
 html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}

html {
  --scrollbarBG: #0D0D0D;
  --thumbBG: #AA1327;
}
body::-webkit-scrollbar {
  width: 11px;
}
body {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

main {
  display: block;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
hr {
  box-sizing: content-box;
  height: 0; 
  overflow: visible; 
}
pre {
  font-family: monospace, monospace; 
  font-size: 1em; 
}
a {
  background-color: transparent;
  text-decoration:none;
}
abbr[title] {
  border-bottom: none; 
  text-decoration: underline; 
  text-decoration: underline dotted; 
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
samp {
  font-family: monospace, monospace; 
  font-size: 1em; 
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}
img {
  border-style: none;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit; 
  font-size: 100%; 
  line-height: 1.15; 
  margin: 0; 
}
button,
input { 
  overflow: visible;
}
button,
select { 
  text-transform: none;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
legend {
  box-sizing: border-box; 
  color: inherit; 
  display: table; 
  max-width: 100%; 
  padding: 0; 
  white-space: normal; 
}
progress {
  vertical-align: baseline;
}
textarea {
  overflow: auto;
}
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box; 
  padding: 0; 
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield; 
  outline-offset: -2px; 
}
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button; 
  font: inherit; 
}
details {
  display: block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none;
}




@font-face { 
  font-family: "GothamPro";
  src: url("fonts/GothamProLight/GothamProLight.eot");
  src: url("fonts/GothamProLight/GothamProLight.eot?#iefix")format("embedded-opentype"),
  url("fonts/GothamProLight/GothamProLight.woff") format("woff"),
  url("fonts/GothamProLight/GothamProLight.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
}
@font-face { 
  font-family: "GothamPro";
  src: url("fonts/GothamProRegular/GothamProRegular.eot");
  src: url("fonts/GothamProRegular/GothamProRegular.eot?#iefix")format("embedded-opentype"),
  url("fonts/GothamProRegular/GothamProRegular.woff") format("woff"),
  url("fonts/GothamProRegular/GothamProRegular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}
@font-face { 
  font-family: "GothamPro";
  src: url("fonts/GothamProMedium/GothamProMedium.eot");
  src: url("fonts/GothamProMedium/GothamProMedium.eot?#iefix")format("embedded-opentype"),
  url("fonts/GothamProMedium/GothamProMedium.woff") format("woff"),
  url("fonts/GothamProMedium/GothamProMedium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}
@font-face { 
  font-family: "GothamPro";
  src: url("fonts/GothamProBold/GothamProBold.eot");
  src: url("fonts/GothamProBold/GothamProBold.eot?#iefix")format("embedded-opentype"),
  url("fonts/GothamProBold/GothamProBold.woff") format("woff"),
  url("fonts/GothamProBold/GothamProBold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}
@font-face { 
  font-family: "GothamPro";
  src: url("fonts/GothamProBlack/GothamProBlack.eot");
  src: url("fonts/GothamProBlack/GothamProBlack.eot?#iefix")format("embedded-opentype"),
  url("fonts/GothamProBlack/GothamProBlack.woff") format("woff"),
  url("fonts/GothamProBlack/GothamProBlack.ttf") format("truetype");
  font-style: normal;
  font-weight: 900;
}
@font-face { 
  font-family: "GothamPro";
  src: url("fonts/GothamProLightItalic/GothamProLightItalic.eot");
  src: url("fonts/GothamProLightItalic/GothamProLightItalic.eot?#iefix")format("embedded-opentype"),
  url("fonts/GothamProLightItalic/GothamProLightItalic.woff") format("woff"),
  url("fonts/GothamProLightItalic/GothamProLightItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: normal;
}
@font-face { 
  font-family: "GothamPro";
  src: url("fonts/GothamProItalic/GothamProItalic.eot");
  src: url("fonts/GothamProItalic/GothamProItalic.eot?#iefix")format("embedded-opentype"),
  url("fonts/GothamProItalic/GothamProItalic.woff") format("woff"),
  url("fonts/GothamProItalic/GothamProItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: normal;
}
@font-face { 
  font-family: "GothamPro";
  src: url("fonts/GothamProMediumItalic/GothamProMediumItalic.eot");
  src: url("fonts/GothamProMediumItalic/GothamProMediumItalic.eot?#iefix")format("embedded-opentype"),
  url("fonts/GothamProMediumItalic/GothamProMediumItalic.woff") format("woff"),
  url("fonts/GothamProMediumItalic/GothamProMediumItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 500;
}
@font-face { 
  font-family: "GothamPro";
  src: url("fonts/GothamProBoldItalic/GothamProBoldItalic.eot");
  src: url("fonts/GothamProBoldItalic/GothamProBoldItalic.eot?#iefix")format("embedded-opentype"),
  url("fonts/GothamProBoldItalic/GothamProBoldItalic.woff") format("woff"),
  url("fonts/GothamProBoldItalic/GothamProBoldItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 700;
}
@font-face { 
  font-family: "GothamPro";
  src: url("fonts/GothamProBlackItalic/GothamProBlackItalic.eot");
  src: url("fonts/GothamProBlackItalic/GothamProBlackItalic.eot?#iefix")format("embedded-opentype"),
  url("fonts/GothamProBlackItalic/GothamProBlackItalic.woff") format("woff"),
  url("fonts/GothamProBlackItalic/GothamProBlackItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 900;
}








* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
body {
  background: #0D0A13;
  font-family: "GothamPro";
}
.header-wrapper {
  position: fixed;
  width: 100%;
  height: 102px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.20);
  background: #0D0A13;
  left:50%;
  transform:translateX(-50%); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.header {
  width: 1468px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header__bar {
  width: 623px;
  display: flex;
  justify-content: space-between;
}
.header__left {
  display: flex;
  align-items: center;
}
.header__logo {
  margin-right: 96px;
}
.header__tab {
  display: flex;
  align-items: center;
  transition: 0.5s;
  color: rgba(255, 255, 255, 0.40);
  font-family: "GothamPro";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 80% */
  text-transform: capitalize;

}
.header__tab:hover {
  color: rgba(255, 255, 255, 1);
}
.button__size {
  text-align: center;
  font-size: 18px;
  max-height: 53px;
  width: 100%;
  border-radius: 12px;
  padding: 16px 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 16.667px;
}
.button__transparent {
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.10);
  color: #fff;
}
.button__transparent:hover {
  background: rgba(255, 255, 255, 0.20);
}
.button__style {
  background: #BBEC6C;
  color: #000;
}
.button__style:hover {
  opacity: 0.8;
}
.mob-menu {
  display: none;
}
.bg-wrapper {
  display: block;
  width: 100%;
  margin: 0 auto;
  padding-top: 102px;
  background-image: url('./img/looper.png'), url('./img/cell1.png'), url('./img/cell2.png'), url('./img/cell3.png'), url('./img/cell4.png');
  background-position: 116% 3%, 8% 7%, right bottom, left 85%, 20% 35%;
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
  @media (max-width: 1845px) {
    background-position: 116% 3%, 8% 7%, right bottom, left 85%, 20% 35%;
  }
  @media (max-width: 1700px) {
    background-position: 116% 3%, 8% 7%, right bottom, left 85%, 20% 35%;
  }
}
.intro {
  max-width: 1468px;
  padding-top: 64px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.banner__text {
  max-width: 567px;
  margin-top: 52px;

  color: var(--white, #FFF);

  font-family: "GothamPro";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 145.455% */
}
.banner__text_span {
  word-break: break-all;
  color: var(--3453465, var(--white, #BBEC6C));
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}
.banner__logo {
  width: 100%;
}
.links {
  margin-top: 32px;
}
.girl {
  position: relative;
  margin-right: 42px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.girl__trybuy {
  padding: 8px;
  border-radius: 20px;
  background: var(--342, linear-gradient(92deg, #1A251F 5.53%, #1B1227 97.6%));
  max-width: 625px;
  display: grid;
  gap: 8px;
  grid-template-columns: 0.6fr 0.375fr 1fr;
  grid-template-rows: auto auto;
  grid-template-areas: "a a b"
                       "c d d";
}
.girl__pic {
  max-width: 800px;
  width: 100%;
}
.girl__predict {
  grid-area: a;
  padding: 24px 32px;
  border-radius: 12px;
  background: #000;

  color: var(--w, #FFF);
  font-family: Gotham Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase;
}
.girl__predict_span {
  background: var(--Style, linear-gradient(133deg, #B0FF09 24.47%, #05B8F1 109.92%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.girl__metamask {
  grid-area: b;
  padding: 24px 32px;
  border-radius: 12px;
  background: #000;

  color: var(--w, #FFF);
  font-family: "GothamPro";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase;
}
.girl__metamask_span {
  background: var(--1, linear-gradient(93deg, #AA1327 13.59%, #FF465E 87.82%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.girl__try {
  grid-area: c;
  background: #000;
  text-align: center;
  font-size: 18px;
  max-height: 53px;
  border-radius: 12px;
  padding: 16px 32px;
  color: #FFF;
  font-style: normal;
  font-weight: 700;
  line-height: 16.667px;
  text-transform: uppercase;
}
.girl__try:hover {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%), #000;
}
.girl__buy {
  grid-area: d;
  text-align: center;
  font-size: 18px;
  max-height: 53px;
  border-radius: 12px;
  padding: 16px 32px;
  color: #FFF;
  font-style: normal;
  font-weight: 700;
  line-height: 16.667px; /* 92.593% */
  text-transform: uppercase;
  background: var(--1, linear-gradient(93deg, #AA1327 13.59%, #FF465E 87.82%));
}
.girl__buy:hover {
  background: linear-gradient(266deg, #AA1327 1.1%, #FF465E 95.62%);
}

.tokenomics {
  max-width: 1468px;
  margin: 0 auto;
  display: flex;
  margin-top: 262px;
  /* justify-content: space-between; */
  /* align-items: center; */
}
.tokenomics__title {
  display: flex;
  margin-right: 96px;
}
.tokenomics__blink {
  margin-right: 16px;
  height: 36px;
}
.tokenomics__text {
  color: var(--2, var(--white, #FFF));
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 36px */
  text-transform: uppercase;
}
.tokenomics__items {
  width: 100%;
}
.part {
  border-radius: 12px;
  display: flex;
  padding: 24px 24px 24px 48px;
  justify-content: space-between;
  margin-bottom: 8px;
  border-left: 2px solid var(--3453465, #BBEC6C);
  background: #19161F;
}
.part__airdrop {
  width: 30%;
}
.part__team {
  width: 38%;
}
.part__bank {
  width: 45%;
}
.part__text {
  color: var(--w, var(--white, #FFF));
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  text-transform: capitalize;
}
.part__text_percent {
  /* background: var(--Style, linear-gradient(133deg, #B0FF09 24.47%, #05B8F1 109.92%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */

  color: var(--3453465, var(--white, #BBEC6C));
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 160% */
  text-transform: capitalize;
}
.demo {
  margin: 0 auto;
  max-width: 1468px;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 1045px;
  border-radius: 12px;
  background-image: url('./img/demo.png');
  position: relative;
  margin-top: 128px;
  margin-bottom: 40px;
}
.demo__buttons {
  display: grid;
  gap: 8px;
  grid-template-columns: 220px 220px;
  grid-template-rows: 56px;
  left: 48px;
  bottom: 48px;
  position: absolute;
}
.demo__button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  max-height: 53px;
  width: 100%;
  border-radius: 12px;
  padding: 16px 32px;
  line-height: 16.667px; 


  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  text-transform: capitalize;
}
.demo__text {
  display: flex;
  align-items: center;
  position: absolute;
  top: 120px;
  right: 48px;
}
.demo__blink {
  margin-right: 16px;
}
.demo__fill {
  color: var(--2, #FFF);

  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 43.2px */
  text-transform: uppercase;
}
.demo__fill_span {
  color: var(--3453465, var(--white, #BBEC6C));
}
.taxes {
  margin: 0 auto;
  margin-top: 64px;
  padding-bottom: 157px;
  max-width: 825px;
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
}
.taxes__item {
  height: 379px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;

  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.20);

  background: #0D0A13;
}
.title {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: 128px;
  max-width: 1468px;
}
.title__blink {
  margin-right: 16px;
  height: 36px;
}
.title__text {
  color: var(--2, var(--white, #FFF));
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 36px */
  text-transform: uppercase;
}
.taxes__blinktop {
  position: absolute;
  left: 16px;
  top: 16px;
}
.taxes__blinkbottom {
  position: absolute;
  right: 16px;
  bottom: 16px;
}
.taxes__title {
  text-align: center;
  margin-bottom: 12px;
  

  color: var(--3453465, #BBEC6C);
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px; /* 100% */
  text-transform: uppercase;
}
.taxes__subtitle {

  color: var(--2, var(--white, #FFF));
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 26px */
  text-transform: capitalize;
}
.footer-wrapper {
  height: 188px;
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid rgba(255, 255, 255, 0.20);
  background: #0D0A13;
  backdrop-filter: blur(12px);
}
.footer {
  max-width: 1468px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.footer__text {
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  text-transform: uppercase;
}
.anchor {
  display: block;
  position: relative;
  visibility: hidden;
}
.badge {
  display: flex;
  align-items: center;
  padding: 12px 32px;
  border-radius: 12px;
  border-left: 2px solid var(--Style, #B0FF09);
  background: var(--342, linear-gradient(92deg, #1A251F 5.53%, #1B1227 97.6%));
  position: absolute;
  z-index: 500;
}
.badge__icon {
  margin-right: 12px;
}
.badge__text {
  color: var(--w, #FFF);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  text-transform: uppercase;
}
.badge__text_span {
  background: var(--Style, linear-gradient(133deg, #B0FF09 24.47%, #05B8F1 109.92%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.badge__bal {
  transform: rotate(-3.023deg);
  top: 63%;
  left: -17%;
  @media (max-width: 1370px) {
    left: -5%;
  }
  @media (max-width: 1185px) {
    top: 52%;
    left: 2%;
  }
}
.badge__won {
  transform: rotate(4.762deg);
  right: -22%;
  top: 54%;
  @media (max-width: 1682px) {
    right: -10%;
  }
  @media (max-width: 1185px) {
    top: 44%;
    right: 2%;
  }
}
.badge__lgp {
  right: 4%;
  top: 62%;
  @media (max-width: 1185px) {
    top: 54%;
    right: 4%;
  }
}
@media (max-width: 1500px) {
  .header {
    width: 1150px;
    margin: 0 15px;
  }
  .header__logo {
    margin-right: 32px;
  }
  .bg-wrapper {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (max-width: 1250px) {
  .intro {
    padding-top: 32px;
    flex-direction: column;
  }
  .tokenomics {
    margin-top: 160px;
  }
  .girl {
    margin-right: 0px;
  }

  .bg-wrapper {
    background-position: 250% 0%, 8% 0%, right bottom, left 85%, 20% 50%;
  }
}
@media (max-width: 1184px) {
  .header__bar {
    display: none;
  }
  .header__right {
    display: none;
  }
  .mob-menu {
    display: block;
  }
  .banner__text {
    margin-left: 0px;
    margin-top: 36px;
    max-width: 567px;
  }
  .links {
    margin-left: 0px;
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .girl__pic {
    padding-right: 30px;
    padding-left: 30px;
  }
  .tokenomics__title {
    margin-right: 24px;
  }
  .bg-wrapper {
    background-position: 100% 0%, 8% 0%, right bottom, left 85%, 20% 50%;
  }
}
@media (max-width: 1036px) {
  .tokenomics__title {
    margin-right: 0px;
    margin-bottom: 16px;
  }
  .tokenomics {
    flex-direction: column;
  }
  .part {
    padding: 12px 12px 12px 24px;
  }
  .part__airdrop {
    width: 40%;
  }
  .part__team {
    width: 50%;
  }
  .part__bank {
    width: 60%;
  }
  .demo {
    margin-top: 86px;
    background-image: url('./img/demo2.png');
    height: 800px;
  }
  .demo__buttons {
    grid-template-columns: 220px;
    grid-template-rows: 56px 56px;
    left: 24px;
    bottom: 54px;
  }
  .demo__text {
    top: 160px;
    right: 24px;
    background-color: #000;
    border-radius: 12px;
    padding: 8px;
    margin-left: 24px;
  }
  .taxes {
    margin-top: 48px;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    padding-bottom: 100px;
  }
  .taxes__item {
    height: 270px;
    padding: 20px;
  }
  .title {
    margin-top: 96px;
  }
  .footer__text {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .footer-wrapper {
    background: #121212;
    height: 230px;
    padding-right: 15px;
    padding-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .bg-wrapper {
    background-position: -10% 0%, 8% 0%, right bottom, left 85%, 20% 50%;
  }
  .taxes__title {
    font-size: 36px;
  }
}
@media (max-width: 659px) {
  .badge {
    display: none;
    position: relative;
  }
  .girl__trybuy {
    display: grid;
    gap: 8px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas: "a b"
                         "c c"
                         "d d";
  }
  .girl__predict {
    padding: 12px 16px;
    font-size: 14px;
    line-height: 24px;
  }
  .girl__metamask {
    padding: 12px 16px;
    font-size: 14px;
    line-height: 24px;
  }
  .banner__text {
    font-size: 16px;
  }
  .banner__text_span {
    font-size: 16px;
  }
  .part__airdrop {
    width: 65%;
  }
  .part__team {
    width: 75%;
  }
  .part__bank {
    width: 85%;
  }
  .tokenomics {
    margin-top: 100px;
  }
  .demo__fill {
    font-size: 32px;
    line-height: 120%;
  }
  .footer {
    flex-direction: column;
    align-items: start;
  }
  .footer__logo {
    margin-bottom: 20px;
  }
}